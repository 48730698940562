import React, { useContext } from "react";
import { FormBContext } from "../../../context/FormBContext";
import { NumberInput } from "../../Inputs/NumberInput";
import "./ModelBElasticityGroup.scss";

function ModelBElasticityGroup() {
    const { overrideElasticity, customElasticities, setCustomElasticities, defaultElasticities, elasticityCategorySelection } = useContext(FormBContext);

    const handleElasticityInputGroup = (key: string, e: number | null) => {
        setCustomElasticities({ ...customElasticities, [key]: e });
    };
    return (
        <div className="modelbElasticityGroupComponent">
            {overrideElasticity &&
                Object.keys(customElasticities).map((key, i) => {
                    return (
                        <div key={i} className="elasticityCategoryRow">
                            <p className="categoryName">{key}</p>
                            <div className="categoryInput">
                                <NumberInput
                                    value={customElasticities[key]}
                                    disabled={!elasticityCategorySelection.includes(key)}
                                    name={key}
                                    onChange={(e) => handleElasticityInputGroup(key, e)}
                                    isPositive={false}
                                />
                            </div>
                        </div>
                    );
                })}
            {!overrideElasticity &&
                Object.keys(defaultElasticities).map((key, i) => {
                    return (
                        <div key={i} className="elasticityCategoryRow">
                            <p className="categoryName">{key}</p>
                            <div className="categoryInput">
                                <NumberInput value={defaultElasticities[key]} disabled={true} name={key} onChange={() => {}} isPositive={false} />
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}

export default ModelBElasticityGroup;
